import distance from "jaro-winkler";
import { FORMATTED_NUMBER_REGEXP } from "./primitive";

export const toTitleCase = (text: string) =>
  text && `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`;

export const addTrailing_ = (text: string) => `_${text}`;
export const removeTrailing_ = (text: string) =>
  /^_/.test(text) ? text.slice(1) : text;

export const numberFormatted = (num: number) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const numberAsStringFormatted = (num: string) =>
  num?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const commaFormatted = (value: string | number) =>
  typeof value === "string" && FORMATTED_NUMBER_REGEXP.test("" + value)
    ? numberAsStringFormatted(value)
    : typeof value === "number"
    ? numberFormatted(value)
    : value;

export const numberToCurrency = (
  num = 0,
  options?: { maximumFractionDigits?: number }
) =>
  num.toLocaleString(
    "en-US",
    options ?? { style: "currency", currency: "USD" }
  );

export const numberToCurrencyShort = (number = 0) =>
  `$${numberToCurrency(number, { maximumFractionDigits: 2 })}`;

// inspired heavily by https://stackoverflow.com/a/14919494
export const bytesToFileSize = (bytes = 0) => {
  // Paul suggested to do size / 1024 but show as MBs
  // const threshold = si ? 1000 : 1024;
  const threshold = 1024;
  if (Math.abs(bytes) < threshold) return `${bytes} B`;
  // const units = si
  //   ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  //   : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let u = -1;
  do {
    bytes /= threshold;
    ++u;
  } while (Math.abs(bytes) >= threshold && u < units.length - 1);

  return `${bytes.toFixed(1)} ${units[u]}`;
};

export enum NumberAbbreviations {
  K = 1000,
  M = 1000000,
}
const ORDERED_NUM_ABBREVIATIONS = ["K", "M", "B", "T"];
type INumberAbbreviatedArgs = {
  num?: number;
  isCurrency?: boolean;
  startFrom?: NumberAbbreviations;
  customOptions?: Record<string, unknown>;
};

export const numberAbbreviated = ({
  num = 0,
  isCurrency,
  startFrom = NumberAbbreviations.M,
  customOptions = {},
}: INumberAbbreviatedArgs) => {
  const options = isCurrency ? { style: "currency", currency: "USD" } : {};
  const isNegative = num < 0;
  num = Math.abs(num);

  if (num < startFrom) return num.toLocaleString("en-US", options);

  const threshold = 1000;

  let u = -1;
  do {
    num /= threshold;
    ++u;
  } while (
    Math.abs(num) >= threshold &&
    u < ORDERED_NUM_ABBREVIATIONS.length - 1
  );

  return `${isNegative ? "-" : ""}${num.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    ...customOptions,
    ...options,
  })}${ORDERED_NUM_ABBREVIATIONS[u]}`;
};

export const abbreviateAndFormat = ({
  num,
  isCurrency,
  startFrom = NumberAbbreviations.M,
}: INumberAbbreviatedArgs) => {
  // eslint-disable-next-line eqeqeq
  if (num == undefined) return "-";

  return numberAbbreviated({ num, isCurrency, startFrom });
};

export const parseResponse = (text: string): { [key: string]: string } => {
  return text
    .substring(0, text.length - 1)
    .replace("Response(", "")
    .split(",")
    .reduce((acc, item) => {
      const [key, value] = item.split("=");
      return { ...acc, [key.trim()]: value.trim() };
    }, {});
};

type safelyParseJsonOptions = {
  handleError?: (text: string) => void;
  shouldReturnOriginalOnFail?: boolean;
  defaultValue?: any;
};
export const safelyParseJson = (
  text: string,
  {
    handleError,
    shouldReturnOriginalOnFail,
    defaultValue,
  }: safelyParseJsonOptions = {
    defaultValue: {},
  }
) => {
  let result = defaultValue;
  try {
    result = JSON.parse(text);
  } catch (error) {
    if (handleError) {
      result = handleError(text);
    }
    if (shouldReturnOriginalOnFail) {
      result = text;
    }
  }
  return result;
};

// inspired heavily by https://stackoverflow.com/a/9462382
const si = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "k" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "B" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
];

export const shortSiNumber = (num: number, toFixed: number) => {
  const trailingZeroRegex = /\.0+$|(\.[0-9]*[1-9])0+$/;

  for (let i = si.length - 1; i > -1; i--) {
    if (Math.abs(num) >= si[i].value) {
      return (
        (num / si[i].value).toFixed(toFixed).replace(trailingZeroRegex, "$1") +
        si[i].symbol
      );
    }
  }
};

export const replaceAll = (str: string, find: string, replaceWith: string) => {
  if (find === replaceWith) return str;

  return str.replace(new RegExp(find, "gm"), replaceWith);
};

export const fuzzyMatch = (input, listItem) =>
  listItem
    ? listItem.toLowerCase().includes(input.toLowerCase()) ||
      distance(
        listItem
          .replace(/[^a-zA-Z0-9]/g, "")
          .substring(0, input.length + 2)
          .toLowerCase(),
        input.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
      ) > 0.7
    : false;

export const getRowsNumber = (text: string) => {
  const regExp = /\n/g;
  const matches = text.match(regExp);
  return matches ? matches.length + 1 : 0;
};
