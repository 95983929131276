import { combineReducers } from "redux";
import audiencesReducer from "routes/Audiences/audiencesSlice";
import appsReducer from "./apps/appsSlice";
import authReducer from "./auth/authSlice";
// vs-code-organize-imports-disable-next-line
import campaignOptimiserReducer from "./campaignOptimiser/campaignOptimiserSlice";
// vs-code-organize-imports-disable-next-line
import campaignsReducer from "./campaigns/campaignsSlice";
// vs-code-organize-imports-disable-next-line
import campaignsBudgetsReducer from "./campaignsBudgets/budgetsSlice";
// vs-code-organize-imports-disable-next-line
import campaignsStatusReducer from "./campaignStatus/campaignStatusSlice";
import creativesReducer from "./creatives/creativesSlice";
import analyticsCsvDownloadsReducer from "./csvDownloads/analyticsCsvDownloadsSlice";
import inventoryCsvDownloadsReducer from "./csvDownloads/inventoryCsvDownloadsSlice";
import trafficCsvDownloadsReducer from "./csvDownloads/trafficCsvDownloadsSlice";
import dictionaryReducer from "./dictionary/reducer";
import inAppEventsReducer from "./inAppEvents/inAppEventsSlice";
import monetizationAppsReducer from "./monetizationApps/monetizationAppsSlice";
import playablesReducer from "./playables/playablesSlice";
import predictReducer from "./predict/predictSlice";
import reporterReducer from "./reporter/reducer";
import skadBlocksReducer from "./skadBlocks/skadBlocksSlice";
import skadTemplatesReducer from "./skadTemplates/skadTemplatesSlice";
import trafficPageReducer from "./trafficPage/trafficSlice";
import viewSettingsReducer from "./viewSettings/viewSettingsSlice";

const accountDependentReducers = {
  campaigns: campaignsReducer,
  viewSettings: viewSettingsReducer,
  campaignsStatus: campaignsStatusReducer,
  reporter: reporterReducer,
  audiences: audiencesReducer,
  creatives: creativesReducer,
  playables: playablesReducer,
  predict: predictReducer,
  trafficPage: trafficPageReducer,
  inAppEvents: inAppEventsReducer,
  monetizationApps: monetizationAppsReducer,
  analyticsCsvDownloads: analyticsCsvDownloadsReducer,
  trafficCsvDownloads: trafficCsvDownloadsReducer,
  inventoryCsvDownloads: inventoryCsvDownloadsReducer,
  campaignsBudgets: campaignsBudgetsReducer,
};

const accountAgnosticReducers = {
  campaignOptimiser: campaignOptimiserReducer,
  dictionaries: dictionaryReducer,
  apps: appsReducer,
  skadTemplates: skadTemplatesReducer,
  skadBlocks: skadBlocksReducer,
};

const reducer = combineReducers({
  auth: authReducer,
  ...accountDependentReducers,
  ...accountAgnosticReducers,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (
    action.type === "auth/loginWithCredentials/pending" ||
    action.type === "auth/logout/fulfilled" ||
    action.type === "auth/expireSession"
  ) {
    newState = undefined;
  }

  if (
    action.type === "auth/setAccount" ||
    action.type === "auth/setOrganization"
  ) {
    newState = {
      auth: state.auth,
    };
    Object.keys(accountAgnosticReducers).forEach(
      (r) => (newState[r] = state[r])
    );
  }

  return reducer(newState, action);
};

export default rootReducer;

export type IRootState = ReturnType<typeof rootReducer>;
