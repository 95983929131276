import { sortAlphaNum } from "helpers";

export type ISortFunc = (
  id: string,
  desc: boolean
) => (a: any, b: any) => number;
export const sortColumnAlpha: ISortFunc = (id, desc) => (a, b) => {
  const B = "" + b[id];
  const A = "" + a[id];
  if (!B || !A) return 0;
  return desc ? B.localeCompare(A) : A?.localeCompare(B);
};

export const sortColumnNumeric: ISortFunc = (id, desc) => (a, b) =>
  desc
    ? parseFloat(b[id]) - parseFloat(a[id])
    : parseFloat(a[id]) - parseFloat(b[id]);

export const sortColumnAlphaNumeric: ISortFunc = (id, desc) => (a, b) =>
  desc ? sortAlphaNum(b[id], a[id]) : sortAlphaNum(a[id], b[id]);
