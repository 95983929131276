import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Tooltip as MuiTooltip } from "components/mui-core";
import { TooltipProps as MuiTooltipProps } from "components/mui-core-types";
import React from "react";
import { whiteColor } from "vendor/colors";

type TooltipProps = {
  className?: string;
  title?: React.ReactNode;
  placement?: "left" | "top" | "right" | "bottom";
  arrow?: boolean;
  children?: React.ReactNode;
  tooltipProps?: MuiTooltipProps;
  style?;
};

export const LightTooltip = styled(
  ({
    className,
    isRenderedFromDialog,
    title,
    ...props
  }: MuiTooltipProps & {
    isRenderedFromDialog?: boolean;
  }) => {
    // This is needed to workoraound for issue
    // when the tooltip is rendered from a dialog, it's content/text is not selectable
    const tooltipContent = isRenderedFromDialog ? (
      <div tabIndex={1}>{title}</div>
    ) : (
      title
    );

    return (
      <MuiTooltip
        {...props}
        title={tooltipContent}
        classes={{ popper: className }}
      />
    );
  }
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: whiteColor,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function Tooltip({
  className,
  title,
  children,
  placement,
  arrow,
  tooltipProps,
  style,
}: TooltipProps) {
  if (!children) return null;

  if (!title) return <span className={className}>{children}</span>;

  return (
    <MuiTooltip
      className={className}
      title={title}
      arrow={arrow}
      placement={placement}
      style={style}
      {...tooltipProps}>
      <span>{children}</span>
    </MuiTooltip>
  );
  // Span around children makes tooltip work for disabled buttons:
  // https://material-ui.com/components/tooltips/#disabled-elements
}

export default Tooltip;
