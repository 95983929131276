import AccountIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  useAccountId,
  useIsLoggedIn,
  useOrganizationId,
  useUsername,
} from "redux/auth/selectors";
import AdminSelect from "./AdminSelect";

function UserInfo({ classes }) {
  const username = useUsername();
  const accountId = useAccountId();
  const organizationId = useOrganizationId();
  const isLoggedIn = useIsLoggedIn();
  const [isExpanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setExpanded(false);
  }, [accountId, organizationId]);

  if (!isLoggedIn) return <Redirect to="/login/" />;

  return (
    <>
      <ListItem
        data-qa="button_expand-organization"
        classes={{
          root: classes.listItem,
        }}
        onClick={() => setExpanded(!isExpanded)}>
        <ListItemIcon className={classes.icon}>
          <AccountIcon />
        </ListItemIcon>
        <span data-qa="text_current-user">{username}</span>
        {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </ListItem>
      <Collapse in={isExpanded}>
        <AdminSelect
          classes={classes}
          username={username}
        />
      </Collapse>
    </>
  );
}

export default UserInfo;
