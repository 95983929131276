"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endOf = exports.startOf = exports.subtractPeriod = exports.getDateRangeBoundary = exports.getDateRange = exports.getDateRangeDict = exports.getDateRangeOptionByPeriodConfig = exports.DATE_RANGE_DICT = exports.PERIOD_CONFIGS = exports.periodTypesToSingular = exports.PERIOD_SINGULAR_TYPES = exports.PERIOD_TYPES = void 0;
const dateISO_1 = require("../../helpers/dateISO");
const dateRange_1 = require("../genericReport/dateRange");
const granularity_1 = require("../reporterDb/granularity");
exports.PERIOD_TYPES = {
    MONTHS: "months",
    DAYS: "days",
    HOURS: "hours",
    ISO_WEEKS: "weeks",
};
exports.PERIOD_SINGULAR_TYPES = {
    MONTH: "month",
    DAY: "day",
    HOUR: "hour",
    ISO_WEEK: "week",
};
function periodTypesToSingular(pt) {
    switch (pt) {
        case "months":
            return "month";
        case "days":
            return "day";
        case "hours":
            return "hour";
        case "weeks":
            return "week";
        default:
            return String(pt).substring(0, String(pt).length - 2);
    }
}
exports.periodTypesToSingular = periodTypesToSingular;
exports.PERIOD_CONFIGS = {
    last30Days: {
        period: exports.PERIOD_TYPES.DAYS,
        granularity: granularity_1.GRANULARITY_TYPES.DAYS,
        startUnitsAgo: 30,
        endUnitsAgo: 1,
        label: "Last 30 Days",
    },
    last14Days: {
        period: exports.PERIOD_TYPES.DAYS,
        granularity: granularity_1.GRANULARITY_TYPES.DAYS,
        startUnitsAgo: 14,
        endUnitsAgo: 1,
        label: "Last 14 Days",
    },
    last7Days: {
        period: exports.PERIOD_TYPES.DAYS,
        startUnitsAgo: 7,
        endUnitsAgo: 1,
        label: "Last 7 Days",
    },
    today: {
        period: exports.PERIOD_TYPES.DAYS,
        startUnitsAgo: 0,
        endUnitsAgo: 0,
        label: "Today",
    },
    yesterday: {
        period: exports.PERIOD_TYPES.DAYS,
        startUnitsAgo: 1,
        endUnitsAgo: 1,
        label: "Yesterday",
    },
    thisWeek: {
        period: exports.PERIOD_TYPES.ISO_WEEKS,
        startUnitsAgo: 0,
        endUnitsAgo: 0,
        label: "This Week",
    },
    lastWeek: {
        period: exports.PERIOD_TYPES.ISO_WEEKS,
        startUnitsAgo: 1,
        endUnitsAgo: 1,
        label: "Last Week",
    },
    thisMonth: {
        period: exports.PERIOD_TYPES.MONTHS,
        startUnitsAgo: 0,
        endUnitsAgo: 0,
        label: "This Month",
    },
    mtd: {
        period: exports.PERIOD_TYPES.MONTHS,
        startUnitsAgo: 0,
        endUnitsAgo: 0,
        label: "MTD",
    },
    lastMonth: {
        period: exports.PERIOD_TYPES.MONTHS,
        startUnitsAgo: 1,
        endUnitsAgo: 1,
        label: "Last Month",
    },
};
exports.DATE_RANGE_DICT = {
    [dateRange_1.TimeRange.today]: { value: dateRange_1.TimeRange.today, label: "Today" },
    [dateRange_1.TimeRange.yesterday]: { value: dateRange_1.TimeRange.yesterday, label: "Yesterday" },
    [dateRange_1.TimeRange.last7Days]: { value: dateRange_1.TimeRange.last7Days, label: "Last 7 Days" },
    [dateRange_1.TimeRange.lastWeek]: { value: dateRange_1.TimeRange.lastWeek, label: "Last Week" },
    [dateRange_1.TimeRange.last30Days]: {
        value: dateRange_1.TimeRange.last30Days,
        label: "Last 30 Days",
    },
    [dateRange_1.TimeRange.mtd]: { value: dateRange_1.TimeRange.mtd, label: "MTD" },
    [dateRange_1.TimeRange.lastMonth]: { value: dateRange_1.TimeRange.lastMonth, label: "Last Month" },
    [dateRange_1.TimeRange.custom]: { value: dateRange_1.TimeRange.custom, label: "Custom" },
};
const getStringIdFromRange = ({ startUnitsAgo, endUnitsAgo, period, unit, }) => {
    //Below, fallback for server side renaming 'period' to 'unit' deployment.
    //Use 'unit' only and remove this after server-side deploy
    const periodOrUnit = period || unit;
    return `start_${startUnitsAgo}_end_${endUnitsAgo}_${periodOrUnit}_ago`;
};
const periodConfigDict = Object.entries(exports.PERIOD_CONFIGS).reduce((acc, [key, value]) => {
    acc[getStringIdFromRange(value)] = { value: key, label: value.label };
    return acc;
}, {});
const getDateRangeOptionByPeriodConfig = (range) => {
    var _a;
    const defaultOption = { value: "custom", label: "Custom" };
    if (!range)
        return defaultOption;
    const stringified = getStringIdFromRange(range);
    return (_a = periodConfigDict === null || periodConfigDict === void 0 ? void 0 : periodConfigDict[stringified]) !== null && _a !== void 0 ? _a : defaultOption;
};
exports.getDateRangeOptionByPeriodConfig = getDateRangeOptionByPeriodConfig;
let dateRangesCache = {};
//pass 'now' in arg for testing only
const getDateRangeDict = (now = (0, dateISO_1.getIsoDateNow)()) => {
    const datestamp = (0, dateISO_1.getIsoDayPortion)(now);
    const cache = dateRangesCache[datestamp];
    if (cache)
        return cache;
    const endOfToday = (0, dateISO_1.endOfIsoDay)(now);
    const yesterday = (0, dateISO_1.subDaysIso)(now, 1);
    const day7BeforeToday = (0, dateISO_1.subDaysIso)(now, 7);
    const day30BeforeToday = (0, dateISO_1.subDaysIso)(now, 30);
    const startOfThisWeek = (0, dateISO_1.startOfISOWeekIso)(now);
    const startOfLastWeek = (0, dateISO_1.subDaysIso)(startOfThisWeek, 7);
    const startOfThisMonth = (0, dateISO_1.startOfMonthIso)(now);
    const thisMonthLength = (0, dateISO_1.differenceInDaysIso)(now, startOfThisMonth);
    // MTD is Month 'Till Day - period from month start until yesterday (today not included)
    const mtdAvailable = thisMonthLength >= 1; // if today is the first day of the month, length will be 0
    const startOfLastMonth = (0, dateISO_1.subMonthsIso)(startOfThisMonth, 1);
    const ranges = {
        [dateRange_1.TimeRange.today]: {
            start: (0, dateISO_1.startOfIsoDay)(now),
            finish: (0, dateISO_1.endOfIsoDay)(now),
            calcStart: () => (0, dateISO_1.startOfIsoDay)((0, dateISO_1.getIsoDateNow)()),
            calcFinish: () => (0, dateISO_1.getIsoDateNow)(),
        },
        [dateRange_1.TimeRange.yesterday]: {
            start: (0, dateISO_1.startOfIsoDay)(yesterday),
            finish: (0, dateISO_1.endOfIsoDay)(yesterday),
        },
        [dateRange_1.TimeRange.last7Days]: {
            start: (0, dateISO_1.startOfIsoDay)(day7BeforeToday),
            finish: (0, dateISO_1.endOfIsoDay)(yesterday),
        },
        [dateRange_1.TimeRange.lastWeek]: {
            start: startOfLastWeek,
            finish: (0, dateISO_1.endOfISOWeekIso)(startOfLastWeek),
        },
        [dateRange_1.TimeRange.last30Days]: {
            start: (0, dateISO_1.startOfIsoDay)(day30BeforeToday),
            finish: (0, dateISO_1.endOfIsoDay)(yesterday),
        },
        [dateRange_1.TimeRange.mtd]: {
            start: startOfThisMonth,
            finish: mtdAvailable ? (0, dateISO_1.endOfIsoDay)(yesterday) : endOfToday,
            disabled: !mtdAvailable,
        },
        [dateRange_1.TimeRange.lastMonth]: {
            start: startOfLastMonth,
            finish: (0, dateISO_1.endOfMonthIso)(startOfLastMonth),
        },
    };
    dateRangesCache = { [datestamp]: ranges };
    return ranges;
};
exports.getDateRangeDict = getDateRangeDict;
const getDateRange = (range) => {
    const rangesList = (0, exports.getDateRangeDict)();
    return rangesList[range];
};
exports.getDateRange = getDateRange;
const getDateRangeBoundary = (range) => {
    const dateRange = (0, exports.getDateRange)(range);
    if (!dateRange)
        return { start: undefined, finish: undefined };
    return {
        start: dateRange.start,
        finish: dateRange.finish,
    };
};
exports.getDateRangeBoundary = getDateRangeBoundary;
const subtractPeriod = (date, subtract, period) => {
    switch (period) {
        case exports.PERIOD_TYPES.MONTHS:
            return (0, dateISO_1.subMonthsIso)(date, subtract);
        case exports.PERIOD_TYPES.ISO_WEEKS:
            return (0, dateISO_1.subWeeksIso)(date, subtract);
        case exports.PERIOD_TYPES.DAYS:
            return (0, dateISO_1.subDaysIso)(date, subtract);
        case exports.PERIOD_TYPES.HOURS:
            return (0, dateISO_1.subHoursIso)(date, subtract);
        default:
            return (0, dateISO_1.subIso)(date, { [period]: subtract });
    }
};
exports.subtractPeriod = subtractPeriod;
const startOf = (date, period) => {
    switch (period) {
        case exports.PERIOD_SINGULAR_TYPES.MONTH:
            return (0, dateISO_1.startOfMonthIso)(date);
        case exports.PERIOD_SINGULAR_TYPES.ISO_WEEK:
            return (0, dateISO_1.startOfISOWeekIso)(date);
        case exports.PERIOD_SINGULAR_TYPES.DAY:
            return (0, dateISO_1.startOfIsoDay)(date);
        case exports.PERIOD_SINGULAR_TYPES.HOUR:
            return (0, dateISO_1.startOfHourIso)(date);
        default:
            return date;
    }
};
exports.startOf = startOf;
const endOf = (date, period) => {
    switch (period) {
        case exports.PERIOD_SINGULAR_TYPES.MONTH:
            return (0, dateISO_1.endOfMonthIso)(date);
        case exports.PERIOD_SINGULAR_TYPES.ISO_WEEK:
            return (0, dateISO_1.endOfISOWeekIso)(date);
        case exports.PERIOD_SINGULAR_TYPES.DAY:
            return (0, dateISO_1.endOfIsoDay)(date);
        case exports.PERIOD_SINGULAR_TYPES.HOUR:
            return (0, dateISO_1.endOfHourIso)(date);
        default:
            return date;
    }
};
exports.endOf = endOf;
