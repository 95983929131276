import * as Sentry from "@sentry/browser";
import { getEnvironment } from "configuration/endpoints/environments";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

//eslint-disable-next-line no-undef
if (getEnvironment().useSentry) {
  Sentry.init({
    dsn: "https://6ca0adaa23c440baba66b97daa95fda5@o572394.ingest.us.sentry.io/5721742",
    integrations: [],
    tracesSampleRate: 0,
  });
}
const root = createRoot(document.getElementById("root"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
