import { Microdollars } from "model/modelTypes";

export type TSequenceIndex = 0 | 1 | 2;
export type TCoarse = "low" | "medium" | "high";
export type TCvIdfrom0to63 = string; //from '0' to '63'
export type TSequenceItem = {
  conversionValue: TCvIdfrom0to63 | TCoarse;
  eventName: string;
  purchaseValue?: Microdollars;
};
export type TPostbackConversionValue = {
  sequenceIndex: TSequenceIndex;
  values?: Array<TSequenceItem>;
};

//This is format of data accepted by BE when saving cv
//See example of values in routes/Campaigns/sharedComponents/ConversionValues/mock.ts
export type TPostbackConversionValues = TPostbackConversionValue[];

export enum MMPPartners {
  appsflyer = "appsflyer",
  kochava = "kochava",
  adjust = "adjust",
  branch = "branch",
  singular = "singular",
}
