import { objectIntoQueryString, objectIntoURLSearchParams } from "helpers";
import { MMPPartners } from "model/campaigns";
import { PredictQueryParameters } from "model/lineitems";
import { ManagedModelQueryParameters } from "model/predictModel/predictModelManagerTypes";
import getHosts from "./getHosts";
import { IHistoryParams } from "./types";

const CREATIVE_APPROVAL_EXCHANGE = "google";

const api = {
  health: () => `${getHosts("api")}/healtz`,
  history: (
    organizationId: string,
    accountId: string,
    campaignId: string,
    params: IHistoryParams,
    lineitemId?: string
  ) => {
    let path = `${getHosts(
      "history"
    )}/history/${organizationId}/${accountId}/campaign/${campaignId}`;

    if (lineitemId) {
      path += `/lineitem/${lineitemId}`;
    }

    return `${path}?${objectIntoURLSearchParams(params).toString()}`;
  },
  stateHistory: (
    organizationId: string,
    accountId: string,
    campaignId: string,
    params: IHistoryParams,
    lineitemId?: string
  ) => {
    let path = `${getHosts(
      "history"
    )}/history/state/${organizationId}/${accountId}/campaign/${campaignId}`;

    if (lineitemId) {
      path += `/lineitem/${lineitemId}`;
    }

    return `${path}?${objectIntoURLSearchParams(params).toString()}`;
  },
  //this storage is currently not used, probably deprecated
  storageByAccount: (organizationId, accountId) =>
    `${getHosts("api")}/dashboards/${organizationId}/${accountId}`,
  //this storage is currently not used, probably deprecated
  deleteFromStorage: (organizationId, accountId, dashboardId) =>
    `${getHosts(
      "api"
    )}/dashboards/${organizationId}/${accountId}/${dashboardId}`,
  campaignsByAccount: (organizationId, accountId, isArchive = false) =>
    `${getHosts("api")}/campaigns/${organizationId}/${accountId}${
      isArchive ? `?archive=${isArchive}` : ""
    }`,
  campaignById: (organizationId, accountId, campaignId) =>
    `${getHosts("api")}/campaigns/${organizationId}/${accountId}/${campaignId}`,
  startCampaign: (organizationId, accountId, campaignId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/${campaignId}/start`,
  stopCampaign: (organizationId, accountId, campaignId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/${campaignId}/stop`,
  archiveCampaign: (organizationId, accountId, campaignId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/delete/${campaignId}`,
  unarchiveCampaign: (organizationId, accountId, campaignId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/unarchive/${campaignId}`,
  startLineItem: (organizationId, accountId, campaignId, lineitemId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/${campaignId}/lineitem/${lineitemId}/start`,
  stopLineItem: (organizationId, accountId, campaignId, lineitemId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/${campaignId}/lineitem/${lineitemId}/stop`,
  archiveLineItem: (organizationId, accountId, campaignId, lineitemId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/${campaignId}/lineitem/archive/${lineitemId}`,
  unarchiveLineItem: (organizationId, accountId, campaignId, lineitemId) =>
    `${getHosts(
      "api"
    )}/campaigns/${organizationId}/${accountId}/${campaignId}/lineitem/unarchive/${lineitemId}`,
  creativesByAccount: (organizationId, accountId, isArchive = false) =>
    `${getHosts("api")}/creatives/${organizationId}/${accountId}${
      isArchive ? `?archive=${isArchive}` : ""
    }`,
  creativeByAccount: (organizationId, accountId, creativeId) =>
    `${getHosts("api")}/creatives/${organizationId}/${accountId}/${creativeId}`,
  updateCreativeByAccount: (organizationId, accountId) =>
    `${getHosts("api")}/creatives/${organizationId}/${accountId}`,
  newCreativeByAccount: (organizationId, accountId) =>
    `${getHosts("api")}/creatives/${organizationId}/${accountId}`,
  archiveCreative: (organizationId, accountId, creativeId) =>
    `${getHosts(
      "api"
    )}/creatives/${organizationId}/${accountId}/archive/${creativeId}`,
  unarchiveCreative: (organizationId, accountId, creativeId) =>
    `${getHosts(
      "api"
    )}/creatives/${organizationId}/${accountId}/unarchive/${creativeId}`,
  creativeApprovalStatus: (organizationId, accountId, creativeId) =>
    `${getHosts("api")}/approval/${organizationId}/${accountId}/${creativeId}`,
  creativeCheckClickThroughUrl: ({ organizationId, accountId, creativeId }) =>
    `${getHosts("api")}/creatives/${organizationId}/${accountId}/${creativeId}`,
  creativeCheckGoogleApproval: ({
    organizationId,
    accountId,
    creativeId,
  }: {
    organizationId: string;
    accountId: string;
    creativeId?: string;
  }) => {
    let path = `${getHosts(
      "api"
    )}/creatives-ext/${CREATIVE_APPROVAL_EXCHANGE}/${organizationId}/${accountId}`;
    if (creativeId) path += `/${creativeId}`;
    return `${path}`;
  },
  creativeSubmitForApproval: ({ organizationId, accountId, creativeId }) => {
    let path = `${getHosts(
      "api"
    )}/creatives-ext/${CREATIVE_APPROVAL_EXCHANGE}/${organizationId}/${accountId}`;
    if (creativeId) path += `/${creativeId}`;
    return `${path}/submit`; // will submit all creatives for approval
  },
  creativeCategories: () =>
    `${getHosts("api")}/creatives/categories/search?name=IAB`,
  creativeAsset: (filename, organizationId, accountId) =>
    `${getHosts(
      "content"
    )}/creatives/${organizationId}/${accountId}/compressed/${filename}`,
  reportUtils: (organizationId, accountId) =>
    `${getHosts("api")}/reportutils/${organizationId}/${accountId}`,
  skadNetworkList: () => `${getHosts("api")}/skadnetwork/list`,
  skadBlocks: (appId) =>
    `${getHosts("api")}/campaigns/skad-block/list/${appId}`,
  blockBasedTemplates: () =>
    `${getHosts("api")}/skadnetwork/list-template-types`,
  getCampaignOptimiserRules: () => `${getHosts("api")}/rules`,
  setBillingCommission: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/billingCommission`,
  setAdServingCommission: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/commission`,
  setMaxCpm: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/maxCpm`,
  findOrgFromCampaign: (campaignId) =>
    `${getHosts("api")}/campaigns/find/campaign/${campaignId}`,
};

// TODO: Refactor all campaign endpoints to here
const campaigns = {
  //budgets
  allCampaignsBudgets: (organizationId, accountId) =>
    `${getHosts("api")}/budgets/${organizationId}/${accountId}`,
  budgets: (organizationId, accountId, campaignId) =>
    `${getHosts("api")}/budgets/${organizationId}/${accountId}/${campaignId}`,
  deactivateBudget: (organizationId, accountId, campaignId, budgetRangeId) =>
    `${getHosts(
      "api"
    )}/budgets/${organizationId}/${accountId}/${campaignId}/deactivate/${budgetRangeId}`,
  updateBudgetValue: (organizationId, accountId, campaignId, budgetRangeId) =>
    `${getHosts(
      "api"
    )}/budgets/${organizationId}/${accountId}/${campaignId}/updateBudgetValue/${budgetRangeId}`,
  //scheduled changes
  scheduledChanges: (organizationId, accountId, campaignId) =>
    `${getHosts(
      "api"
    )}/scheduled-changes/${organizationId}/${accountId}/${campaignId}`,
  deactivateScheduledChange: (
    organizationId,
    accountId,
    campaignId,
    changeId
  ) =>
    `${getHosts(
      "api"
    )}/scheduled-changes/${organizationId}/${accountId}/${campaignId}/deactivateScheduledChange/${changeId}`,
};

const playables = {
  createUpdatePlayable: () => `${getHosts("api")}/playables`,
  deletePlayable: (organizationId, accountId, playableId) =>
    `${getHosts(
      "api"
    )}/playables/${organizationId}/${accountId}/delete/${playableId}`,
  getPlayableById: (organizationId, accountId, playableId) =>
    `${getHosts("api")}/playables/${organizationId}/${accountId}/${playableId}`,
  getPlayables: (organizationId, accountId, isArchived) => {
    const path = `${getHosts("api")}/playables/${organizationId}/${accountId}`;
    if (isArchived) return `${path}?archived=true`;
    return path;
  },
};

const audience = {
  audienceUpload: (organizationId, accountId, type, name) =>
    `${getHosts(
      "audience"
    )}/audience/version/${organizationId}/${accountId}/${type}?name=${name}`,
  audience: (organizationId, accountId, type, name) =>
    `${getHosts(
      "audience"
    )}/audience/${organizationId}/${accountId}/${type}/${name}`,
  audiences: (organizationId, accountId) =>
    `${getHosts("audience")}/audience/${organizationId}/${accountId}`,
  archiveAudience: (organizationId, accountId, type, name) =>
    `${getHosts(
      "audience"
    )}/audience/archive/${organizationId}/${accountId}/${type}/${name}`,
  unarchiveAudience: (organizationId, accountId, type, name) =>
    `${getHosts(
      "audience"
    )}/audience/unarchive/${organizationId}/${accountId}/${type}/${name}`,
};

const account = {
  login: () => `${getHosts("account")}/auth/login`,
  logout: (username) => `${getHosts("account")}/auth/logout?email=${username}`,
  userInfo: () => `${getHosts("account")}/user/current`,
  organizations: () => `${getHosts("api")}/organization`,
  organization: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}`,
  account: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/accounts`,
  users: (organizationId) => `${getHosts("account")}/user/${organizationId}`,
  user: (organizationId, email) =>
    `${getHosts("account")}/user/${organizationId}/${email}`,
  storeUser: (organizationId) =>
    `${getHosts("account")}/user/${organizationId}`,
  setUserPassword: (organizationId) =>
    `${getHosts("account")}/user/${organizationId}/passwd`,
  ban: (username, organizationId) =>
    `${getHosts("account")}/user/${organizationId}/ban/${username}`,
  unban: (username, organizationId) =>
    `${getHosts("account")}/user/${organizationId}/unban/${username}`,
  confirm: (username, organizationId) =>
    `${getHosts("account")}/user/${organizationId}/confirm/${username}`,
  forgotPassword: () => `${getHosts("account")}/user/forgotpasswd`,
  changePassword: (organizationId, apiKey, accessKey) =>
    `${getHosts(
      "account"
    )}/user/${organizationId}/passwd?accessKey=${accessKey}&apiKey=${apiKey}`,
  mfaSetupStart: () => `${getHosts("account")}/mfa/qrCodeSetup`,
  mfaSetupCancel: () => `${getHosts("account")}/mfa/cancelMFASetup`,
  mfaSetupConfirm: () => `${getHosts("account")}/mfa/validateMFASetup`,
  mfaRemove: () => `${getHosts("account")}/mfa/removeMFA`,
  mfaReset: (email) => `${getHosts("account")}/mfa/resetMFA/${email}`,
  mfaForce: (email) => `${getHosts("account")}/mfa/forceMFA/${email}`,
  mfaClearForce: (email) => `${getHosts("account")}/mfa/clearForceMFA/${email}`,
  getTerms: (organizationId) =>
    `${getHosts("account")}/terms/${organizationId}`,
  acceptTerms: (organizationId, version) =>
    `${getHosts("account")}/terms/${organizationId}/accept/${version}`,
  includeAdServingFee: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/includeAdServingFee`,
  removeAdServingFee: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/removeAdServingFee`,
  behalf: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/behalf`,
  paid: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/paid`,
  archiveOrganization: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/archive`,
  unarchiveOrganization: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/unarchive`,
};

const geodb = {
  searchByName: (name) => `${getHosts("geodb")}/geo/search?name=${name}`,
  searchById: (id) => `${getHosts("geodb")}/geo/${id}`,
  searchCountryByCode: (countryCode) =>
    `${getHosts("geodb")}/geo/search/country/${countryCode}`,

  searchMetroCodeByPrefix: (prefix) =>
    `${getHosts("geodb")}/geo/search/metroCodesByPrefix/${prefix}`,
  searchMetroCodeByCode: (code) =>
    `${getHosts("geodb")}/geo/search/metroNameByCode/${code}`,
};

const carrier = {
  search: (name) => `${getHosts("api")}/campaigns/carriers/search?name=${name}`,
};

const dictionary = {
  byKey: (key) => `${getHosts("api")}/dictionary/${key}`,
  metrics: () => `${getHosts("api")}/dictionary/reporter-metrics`,
};

const reporter = {
  storedReportDefinition: (reportId) =>
    `${getHosts("api")}/reportutils/reportdefinition/report/${reportId}`,
  report: (organizationId, accountId) =>
    `${getHosts("reporter")}/report/${organizationId}/${accountId}`,
  dimensions: (table) => `${getHosts("reporter")}/report/dictionary/${table}`,
  storedReportList: (organizationId, accountId) =>
    `${getHosts("api")}/reportutils/${organizationId}/${accountId}`,
  storedReport: (organizationId, accountId, reportId) =>
    `${getHosts(
      "api"
    )}/reportutils/${organizationId}/${accountId}?id=${reportId}`,
  testReportingEmail: (email) =>
    `${getHosts("api")}/scheduledreportservice/testReport?address=${email}`,
  deleteReport: (organizationId, accountId, reportId) =>
    `${getHosts(
      "api"
    )}/reportutils/${organizationId}/${accountId}/delete/${reportId}`,
  forceEmail: () => `${getHosts("nodeServices")}/scheduled-report`,
  subscribers: (reportId) =>
    `${getHosts("api")}/reportutils/subscribers/report/${reportId}`,
  scheduledEmailAs: (reportId, emailAs) =>
    `${getHosts("api")}/reportutils/report/${reportId}/emailAs/${emailAs}`,
  lag: (type = "install") => `${getHosts("reporter")}/report/lag/${type}`,
  exchangeReport: (organizationId) =>
    `${getHosts("reporter")}/exchangeReport/${organizationId}`,
  bundleInAppEvents: (organizationId, accountId, bundleId) =>
    `${getHosts(
      "api"
    )}/reportutils/inappevents/${organizationId}/${accountId}/${bundleId}`,
};

const stats = {
  campaignListStats: (organizationId, accountId) =>
    `${getHosts("api")}/campaigns/statistic/${organizationId}/${accountId}`,
  campaignStats: (organizationId, accountId, campaignId) =>
    `${getHosts(
      "api"
    )}/campaigns/statistic/${organizationId}/${accountId}/${campaignId}`,
  lineItemStats: (organizationId, accountId, campaignId, lineitemId) =>
    `${getHosts(
      "api"
    )}/campaigns/statistic/${organizationId}/${accountId}/${campaignId}/lineitem/${lineitemId}`,
};

const apps = {
  appById: (id) => `${getHosts("publisher")}/apps?id=${id}`,
  apps: () => `${getHosts("publisher")}/apps`,
  appsByBundles: () => `${getHosts("publisher")}/apps`,
  appsByIds: (ids) => `${getHosts("publisher")}/apps?id=${ids.join("&id=")}`,
  appsByGenres: () => `${getHosts("publisher")}/apps/getAppsByGenres`,
  appsBySubgenres: () => `${getHosts("publisher")}/apps/getAppsBySubgenres`,
  appsByContentRating: () =>
    `${getHosts("publisher")}/apps/content-ratings/apps`,
  appCrossAffinity: ({ bundleId, startDate, country }) =>
    `${getHosts(
      "publisher"
    )}/apps/${bundleId}/crossapp-usage?&country=${country}&startDate=${startDate}`,
  fetchAppsFromPublisher: (bundleId, market) =>
    `${getHosts("publisher")}/apps/publisher/${bundleId}/${market}`,
  conversionValues: (mmpPartner: MMPPartners, appBundleId) => {
    switch (mmpPartner) {
      case MMPPartners.kochava:
        return `${getHosts(
          "nodeServices"
        )}/load-cv-from-mmp/kochava/${appBundleId}`;
      case MMPPartners.adjust:
        return `${getHosts(
          "nodeServices"
        )}/load-cv-from-mmp/adjust/${appBundleId}`;
      case MMPPartners.branch:
        return `${getHosts(
          "nodeServices"
        )}/load-cv-from-mmp/branch/${appBundleId}`;
      case MMPPartners.singular:
        return `${getHosts(
          "nodeServices"
        )}/load-cv-from-mmp/singular/${appBundleId}`;
      case MMPPartners.appsflyer:
        return `${getHosts("publisher")}/cv/appsflyer/${appBundleId}`;
      default:
        throw new Error(`Unknown MMP partner: ${mmpPartner}`);
    }
  },
};

const bidder = {
  lineitemForQr: ({
    organizationId,
    accountId,
    exchangeId,
    campaignId,
    lineitemId,
    creativeId,
  }) =>
    `${getHosts(
      "bidderEast"
    )}/mock/rtb/${exchangeId}/bid?${objectIntoQueryString({
      organizationId,
      accountId,
      campaignId,
      lineitemId,
      creativeId,
    })}`,
};

const nodeServices = {
  generateReportAsCsv: () => {
    const csvDownloadUrl = `${getHosts("nodeServices")}/generate-report-as-csv`;
    return csvDownloadUrl;
  },
  generateTrafficReportAsCsv: () => {
    const csvDownloadUrl = `${getHosts(
      "nodeServices"
    )}/generate-traffic-report-as-csv`;
    return csvDownloadUrl;
  },
  loadCsvReportWhenReady: (fileName: string) =>
    `${getHosts("nodeServices")}/load-csv-file/${fileName}`,
  checkS3ObjectExists: (reportId: string) =>
    `${getHosts("nodeServices")}/check-s3-object-exists/${reportId}`,
  listNetworks: () => `${getHosts("nodeServices")}/list-networks`,
  downloadInventoryReport: () => `${getHosts("nodeServices")}/inventory-report`,
  getInventorySummary: () => `${getHosts("nodeServices")}/inventory-summary`,
};

interface IFileSystemHelperProps {
  organizationId?: string;
  accountId?: string;
  filename?;
}

const filesystemHelper =
  (val?: { action }) =>
  ({ organizationId, accountId, filename }: IFileSystemHelperProps) => {
    if (!organizationId || !accountId) {
      throw new Error("Organizqation ID and Account ID must be provided");
    }
    let link = `${getHosts(
      "compression"
    )}/compression/${organizationId}/${accountId}`;
    if (val?.action) {
      link = `${link}/${val?.action}`;
    }
    if (filename) {
      link = `${link}/${filename}`;
    }
    return link;
  };

const filesystem = {
  list: filesystemHelper(),
  upload: filesystemHelper({ action: "upload" }),
  download: filesystemHelper({ action: "download" }),
  /*
  config expects an object:
  {
    "config": {
      "inputPath": "INPUT",
      "outputPath": "OUTPUT",
      "video": {
        "format": "mp4",
        "codec": "libx264",
        "frameRate": 24,
        "frameRatePer": 1,
        "width": 640,
        "height": 480,
        "targetSize": 7000 // the max size of the file (7mb in this case)
        },
        "audio": {
          "codec": "aac",
          "bidRate": 32768,
          "sampleRate": 48000
        }
      }
    }
  */
  config: () => `${getHosts("compression")}/compression/config/sync`,
};

const unsubscribe = {
  getDetails: (unsubscribeId) =>
    `${getHosts("api")}/reportutils/subscribers/decode?id=${unsubscribeId}`,
  unsubscribe: (unsubscribeId) =>
    `${getHosts(
      "api"
    )}/reportutils/subscribers/unsubscribe?id=${unsubscribeId}`,
};

const monetization = {
  getApps: (organizationId, accountId) =>
    `${getHosts("monetizationApps")}/${organizationId}/${accountId}/apps`,
  saveApp: (organizationId, accountId) =>
    `${getHosts("monetizationApps")}/${organizationId}/${accountId}/apps`,
  updateApp: (organizationId, accountId, app, os) =>
    `${getHosts(
      "monetizationApps"
    )}/${organizationId}/${accountId}/apps?app=${app}&os=${os}`,
};

const billingReporter = {
  getReport: () => `${getHosts("billingReporter")}/billing/report`,
  getOrganizationBillingCommission: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/billingCommission`,
  getOrganizationCommission: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/commission`,
  getOrganizationMonthlyMinimum: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/monthlyMinimum`,
  getOrganizationAdServingFeeFlag: (organizationId) =>
    `${getHosts("api")}/organization/${organizationId}/adServing`,
};

const predictMC = {
  predict: ({
    organizationId,
    accountId,
    ...queryParams
  }: PredictQueryParameters & { organizationId; accountId }) =>
    `${getHosts(
      "api"
    )}/predict/${organizationId}/${accountId}?${objectIntoURLSearchParams(
      queryParams
    )}`,
  predictArchive: (organizationId, accountId, modelId) =>
    `${getHosts(
      "api"
    )}/predict/${organizationId}/${accountId}/${modelId}/archive`,
  predictUnarchive: (organizationId, accountId, modelId) =>
    `${getHosts(
      "api"
    )}/predict/${organizationId}/${accountId}/${modelId}/unarchive`,
};
const predictModelManager = {
  managedModel: (organizationId, accountId, modelName) =>
    `${getHosts(
      "predictModelManager"
    )}/v1/model/${organizationId}/${accountId}/${modelName}`,
  filteredModels: (
    organizationId,
    accountId,
    filters: ManagedModelQueryParameters
  ) =>
    `${getHosts(
      "predictModelManager"
    )}/v1/model/${organizationId}/${accountId}?${new URLSearchParams(filters)}`,
};

const endpointMaker = {
  api,
  account,
  carrier,
  dictionary,
  reporter,
  stats,
  apps,
  filesystem,
  audience,
  bidder,
  unsubscribe,
  geodb,
  playables,
  monetization,
  billingReporter,
  nodeServices,
  campaigns,
  predictMC,
  predictModelManager,
};

export default endpointMaker;
