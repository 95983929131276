import {
  TrafficDimensionIds as DimensionIds,
  ITrafficDimension,
  TRAFFIC_FILTERS_DICTIONARY,
  TrafficSplitType,
} from "@dataseat-dsp-client/lib-reporter";

export {
  ETrafficFilterId,
  TRAFFIC_FILTERS_DICTIONARY,
} from "@dataseat-dsp-client/lib-reporter";

export enum ETrafficSplitId {
  GRANULARITY = "GRANULARITY",
}

export const TRAFFIC_SPLITS_DICTIONARY: {
  [key in ETrafficSplitId]: Partial<ITrafficDimension>;
} = {
  [ETrafficSplitId.GRANULARITY]: {
    id: ETrafficSplitId.GRANULARITY,
    name: "Time",
  },
};

const GRANULARITY_DICTIONARY = [
  { value: "ALL", id: "ALL", name: "All" },
  { value: "DAY", id: "DAY", name: "Day" },
  { value: "WEEK", id: "WEEK", name: "Week" },
];

export const GRANULARITY_DIMENSION: TrafficSplitType = {
  type: "Granularity",
  id: DimensionIds.GRANULARITY_COLUMN_ID,
  name: "Time",
  dictionary: GRANULARITY_DICTIONARY,
  isMandatory: false,
};

export const ADDITIONAL_TRAFFIC_DIMENSIONS = [
  GRANULARITY_DIMENSION,
  TRAFFIC_FILTERS_DICTIONARY.CREATIVE_FORMAT_SIZE,
];
