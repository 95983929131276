"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.trafficReport = exports.dbQuery = exports.dbGranularity = exports.dbTableTraffic = exports.dbTableCampaign = exports.dbSchema = exports.modelTypes = exports.inventoryReport = exports.genericReport = exports.analyticsReport = exports.dateISOHelpers = void 0;
// model/reporterDb
// helpers
exports.dateISOHelpers = __importStar(require("./helpers/dateISO"));
// model/analyticsReport
__exportStar(require("./model/analyticsReport"), exports);
exports.analyticsReport = __importStar(require("./model/analyticsReport"));
// model/genericReport
__exportStar(require("./model/genericReport"), exports);
exports.genericReport = __importStar(require("./model/genericReport"));
// model/trafficReport
__exportStar(require("./model/inventoryReport"), exports);
exports.inventoryReport = __importStar(require("./model/inventoryReport"));
// modelTypes
__exportStar(require("./model/modelTypes"), exports);
exports.modelTypes = __importStar(require("./model/modelTypes"));
__exportStar(require("./model/reporterDb/dbSchema"), exports);
exports.dbSchema = __importStar(require("./model/reporterDb/dbSchema"));
__exportStar(require("./model/reporterDb/dbTableCampaign"), exports);
exports.dbTableCampaign = __importStar(require("./model/reporterDb/dbTableCampaign"));
__exportStar(require("./model/reporterDb/dbTableTraffic"), exports);
exports.dbTableTraffic = __importStar(require("./model/reporterDb/dbTableTraffic"));
__exportStar(require("./model/reporterDb/granularity"), exports);
exports.dbGranularity = __importStar(require("./model/reporterDb/granularity"));
__exportStar(require("./model/reporterDb/query"), exports);
exports.dbQuery = __importStar(require("./model/reporterDb/query"));
// model/trafficReport
__exportStar(require("./model/trafficReport"), exports);
exports.trafficReport = __importStar(require("./model/trafficReport"));
