"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = void 0;
const templates_1 = require("../query/templates");
const customEvent_1 = require("./customEvent");
const dimensions_1 = require("./dimensions");
const EXTRA_WHERE_EXPRS = {
    [dimensions_1.DimensionIds.SKAN_CONVERSION_VALUE]: [
        {
            $eq: ["EVENT_NAME", "SKAD_EVENT"],
        },
    ],
};
exports.DIMENSION_AS_MEASURE_SELECT_QUERY = {
    dimensionAsMeasure: ({ eventName, whereValues = [], kind = customEvent_1.CustomEventKind.count, }) => {
        var _a;
        if (kind === customEvent_1.CustomEventKind.sum) {
            //in future, add 'whereValues' support for sum, if/when needed
            return (0, templates_1.sumDimensionTemplate)(eventName);
        }
        const extraWhereExprs = (_a = EXTRA_WHERE_EXPRS[eventName]) !== null && _a !== void 0 ? _a : [];
        return {
            expr: {
                $sum: {
                    args: ["EVENT_COUNT"],
                    where: [{ $in: [eventName, whereValues] }, ...extraWhereExprs],
                },
            },
        };
    },
};
