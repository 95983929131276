import { ISOstring } from "@dataseat-dsp-client/lib-reporter";
import { convertISOtoSameDateTime } from "@dataseat-dsp-client/lib-reporter/build/helpers/dateISO";
import {
  IQuickLink,
  getQuickLinks as getQuickLinksLocalTz,
} from "components/DateTime/quickLinksModelLocalTz";
import { getQuickLinks as getQuickLinksUtc } from "components/DateTime/quickLinksModelUtc";
import { endOfDay, format, isValid, startOfDay } from "date-fns";
import {
  convertISOtoSameDate,
  dateFormat,
  endOfIsoDay,
  parseDate,
  startOfIsoDay,
} from "helpers";
import { TimeRange } from "model/report/dateRange";

export type DateType = Date | string;
export type DateArray = Array<Date | null>;
export type DateTypeArray = Array<DateType | null>;

export type ISODateArray = Array<ISOstring | null>;
export interface DatePickerProps {
  startDate?: DateType | null;
  endDate?: DateType | null;
  valueFormat?: string;
  displayFormat?: string;
}

export const getDateFromValue = (
  date: DateType | null | { value: DateType | null },
  valueFormat?: string
): Date | null => {
  if (date) {
    if (date instanceof Date) return date;

    if (typeof date === "string") return parseDate(date, valueFormat);

    return getDateFromValue(date.value, valueFormat);
  }
  return null;
};

export const getDateFromValueUtc = (
  date: ISOstring | null | { value: ISOstring | null },
  valueFormat?: string
): ISOstring | null => {
  if (date) {
    if (typeof date === "string") return date;

    return date.value;
  }
  return null;
};

export const getDateListFromValues = (
  dates: Array<DateType | null> = [],
  valueFormat?: string
): Array<Date | null> =>
  dates.map((date) => getDateFromValue(date, valueFormat));

export const getDateListFromValuesUtc = (
  dates: Array<ISOstring | null> = [],
  valueFormat?: string
): Array<ISOstring | null> =>
  dates.map((date) => getDateFromValueUtc(date, valueFormat));

export const getParsedDate = ({
  value,
  valueFormat,
}: {
  value?: string | number | Date | null;
  valueFormat?: string;
}): Date | null => {
  if (value) {
    const parsedDate = parseDate(value, valueFormat);
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }

  return null;
};

export const formatBeforeChange = (
  date: Date | null,
  valueFormat?: string
): DateType | null => {
  if (!date) return null;
  if (!valueFormat) return date;

  return format(date, valueFormat);
};

export const formatSelected = (
  value: Date | null,
  valueFormat?: string
): DateType | null => {
  if (!value) return null;

  if (valueFormat) {
    const formatted = format(value, valueFormat);
    return formatted;
  }
  return value;
};

export const isDateError = (
  value: DateType | null,
  valueFormat?: string
): string | null => {
  if (typeof value === "string" && value?.toLowerCase() === "invalid date") {
    const error = `Failed to format date ${value} ${
      valueFormat ? `using format ${valueFormat}` : ""
    }`;
    return error;
  }
  return null;
};

export const formatDate = (
  date: Date | null,
  displayFormat: string,
  valueFormat?: string
): string => {
  if (!date) return "";
  const parsed = parseDate(date, valueFormat);
  return parsed ? format(parsed, displayFormat) : "";
};

export const formatDateUtc = (
  date: ISOstring | null,
  displayFormat: string,
  valueFormat?: string
): string => {
  if (!date) return "";
  const dateObj = convertISOtoSameDate(date);
  const parsed = parseDate(dateObj, valueFormat);
  return dateObj ? format(dateObj, displayFormat) : "";
};

export const getLabelPrevious = (
  startPrevious?: Date | null,
  finishPrevious?: Date | null
): string => {
  if (!startPrevious) return "";

  const startFormatted = format(startPrevious, dateFormat);

  if (!finishPrevious) return startFormatted;

  const finishFormatted = format(finishPrevious, dateFormat);
  const sameDay = startFormatted === finishFormatted;

  return sameDay ? startFormatted : `${startFormatted} - ${finishFormatted}`;
};

export const getLabelPreviousUtc = (
  startPrevious?: ISOstring | null,
  finishPrevious?: ISOstring | null
): string => {
  if (!startPrevious) return "";

  const startFormatted = format(
    convertISOtoSameDateTime(startPrevious),
    dateFormat
  );

  if (!finishPrevious) return startFormatted;

  const finishFormatted = format(
    convertISOtoSameDateTime(finishPrevious),
    dateFormat
  );
  const sameDay = startFormatted === finishFormatted;

  return sameDay ? startFormatted : `${startFormatted} - ${finishFormatted}`;
};

export const getPreviousQuicklink = (
  startPrevious: Date | null,
  finishPrevious: Date | null
): IQuickLink | null => {
  const quickLinks = getQuickLinksLocalTz();
  if (!startPrevious && !finishPrevious)
    return quickLinks[TimeRange.allTime] as IQuickLink;

  if (!startPrevious || !finishPrevious) return null;

  const quickLinkLabel = Object.keys(quickLinks).find((link) => {
    return (
      startOfDay(quickLinks[link].value[0]).toString() ===
        startOfDay(startPrevious).toString() &&
      endOfDay(quickLinks[link].value[1]).toString() ===
        endOfDay(finishPrevious).toString()
    );
  });

  return quickLinkLabel ? quickLinks[quickLinkLabel] : null;
};

export const getPreviousQuicklinkUtc = (
  startPrevious: ISOstring | null,
  finishPrevious: ISOstring | null
): IQuickLink | null => {
  const quickLinks = getQuickLinksUtc();
  if (!startPrevious && !finishPrevious)
    return quickLinks[TimeRange.allTime] as IQuickLink;

  if (!startPrevious || !finishPrevious) return null;

  const quickLinkLabel = Object.keys(quickLinks).find((link) => {
    if (!quickLinks[link].value[0] || !quickLinks[link].value[1]) return null;
    return (
      startOfIsoDay(quickLinks[link].value[0]) ===
        startOfIsoDay(startPrevious) &&
      endOfIsoDay(quickLinks[link].value[1]) === endOfIsoDay(finishPrevious)
    );
  });

  return quickLinkLabel ? quickLinks[quickLinkLabel] : null;
};
