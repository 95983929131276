"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DUMMY_EXPRESSION = exports.GRANULARITY_EXPR = exports.STRICT_MULTI_LIKE_QUERY_CURRY = exports.STRICT_MULTI_LIKE_VALUES_EXPR = exports.STRICT_LIKE_QUERY_CURRY = exports.STRICT_LIKE_VALUES_EXPR = exports.MULTI_LIKE_QUERY_CURRY = exports.MULTI_LIKE_VALUES_EXPR = exports.LIKE_QUERY_CURRY = exports.LIKE_VALUES_EXPR = exports.EXACT_QUERY_CURRY = exports.WHERE_VALUES_EXPR = exports.TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED = exports.TIME_RANGE_EXPR = void 0;
const date_fns_1 = require("date-fns");
const dateISO_1 = require("../../../helpers/dateISO");
const date_1 = require("../../../helpers/date");
//Where date between
function LONG_DATE_TIME_RANGE_EXPR(start, finish) {
    return {
        $between: ["DATETIME", start, finish],
    };
}
function TIME_RANGE_EXPR(start, finish) {
    return LONG_DATE_TIME_RANGE_EXPR((0, dateISO_1.formatISODate)(start, date_1.longDateFormat), (0, dateISO_1.formatISODate)(finish, date_1.longDateFormat));
}
exports.TIME_RANGE_EXPR = TIME_RANGE_EXPR;
function TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED(start, finish) {
    return LONG_DATE_TIME_RANGE_EXPR((0, date_fns_1.format)((0, date_1.parseDate)(start), date_1.longDateFormat), (0, date_fns_1.format)((0, date_1.parseDate)(finish), date_1.longDateFormat));
}
exports.TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED = TIME_RANGE_EXPR_LOCAL_TZ_DEPRECATED;
//Where value in/equal
function WHERE_VALUES_EXPR(measure, values) {
    return values.length === 1
        ? { $eq: [measure, values[0]] }
        : { $in: [measure, values] };
}
exports.WHERE_VALUES_EXPR = WHERE_VALUES_EXPR;
const EXACT_QUERY_CURRY = (measure) => (values) => WHERE_VALUES_EXPR(measure, values);
exports.EXACT_QUERY_CURRY = EXACT_QUERY_CURRY;
//Like
function LIKE_VALUES_EXPR(measure, like) {
    return {
        $ignoreCaseLike: [measure, `%${like}%`],
    };
}
exports.LIKE_VALUES_EXPR = LIKE_VALUES_EXPR;
const LIKE_QUERY_CURRY = (measure) => (like) => LIKE_VALUES_EXPR(measure, like);
exports.LIKE_QUERY_CURRY = LIKE_QUERY_CURRY;
//multi Like
function MULTI_LIKE_VALUES_EXPR(measure, values) {
    return {
        $or: values.map((value) => ({ $ignoreCaseLike: [measure, `%${value}%`] })),
    };
}
exports.MULTI_LIKE_VALUES_EXPR = MULTI_LIKE_VALUES_EXPR;
const MULTI_LIKE_QUERY_CURRY = (measure) => (values) => MULTI_LIKE_VALUES_EXPR(measure, values);
exports.MULTI_LIKE_QUERY_CURRY = MULTI_LIKE_QUERY_CURRY;
//strict Like
function STRICT_LIKE_VALUES_EXPR(measure, like) {
    return {
        $like: [measure, `%${like}%`],
    };
}
exports.STRICT_LIKE_VALUES_EXPR = STRICT_LIKE_VALUES_EXPR;
const STRICT_LIKE_QUERY_CURRY = (measure) => (values) => STRICT_LIKE_VALUES_EXPR(measure, values);
exports.STRICT_LIKE_QUERY_CURRY = STRICT_LIKE_QUERY_CURRY;
//strict multi Like
function STRICT_MULTI_LIKE_VALUES_EXPR(measure, values) {
    return {
        $or: values.map((value) => ({ $like: [measure, value] })),
    };
}
exports.STRICT_MULTI_LIKE_VALUES_EXPR = STRICT_MULTI_LIKE_VALUES_EXPR;
const STRICT_MULTI_LIKE_QUERY_CURRY = (measure) => (values) => STRICT_MULTI_LIKE_VALUES_EXPR(measure, values);
exports.STRICT_MULTI_LIKE_QUERY_CURRY = STRICT_MULTI_LIKE_QUERY_CURRY;
//Granularity
function GRANULARITY_EXPR(value) {
    return {
        field: "DATETIME",
        value,
        direction: "ASC",
    };
}
exports.GRANULARITY_EXPR = GRANULARITY_EXPR;
exports.DUMMY_EXPRESSION = 0;
