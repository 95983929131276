import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import endpointMaker from "configuration/endpoints";
import request from "http/request";
import { LoadingState } from "model/Types/loading";
import type { TSkadBlock } from "model/lineitems/properties/skadNetwork/skadBlock";
import { Integer } from "model/modelTypes";

type TAppSkadBlocks = {
  loadingBlocksState: LoadingState;
  blocksList: TSkadBlock[];
  error: null | SerializedError;
  loadedTimestamp?: Integer;
};
type SkadBlocksState = Record<string, TAppSkadBlocks>;
type TSkadBlocksPayload = { bundleId: string; blocksList: TSkadBlock[] };

export const fetchSkadBlocksThunk = createAsyncThunk<
  TSkadBlocksPayload,
  string
>("skadBlocks/fetchSkadBlocks", async (bundleId: string) => {
  return request
    .getJson(endpointMaker.api.skadBlocks(bundleId))
    .then((resp) => ({
      bundleId,
      blocksList: resp,
    }));
});

//Slice
const skadTemplatesSlice = createSlice({
  name: "skadBlocks",
  initialState: {} as SkadBlocksState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSkadBlocksThunk.pending, (state, action) => {
      const payload = action.payload as TSkadBlocksPayload | undefined;
      if (!payload) return;
      state[payload.bundleId] = {
        loadingBlocksState: LoadingState.loading,
        loadedTimestamp: new Date().getTime(),
        error: null,
        blocksList: [],
      };
    });
    builder.addCase(fetchSkadBlocksThunk.fulfilled, (state, action) => {
      const payload = action.payload as TSkadBlocksPayload | undefined;
      if (!payload) return;
      state[payload.bundleId] = {
        loadingBlocksState: LoadingState.loaded,
        blocksList: action.payload.blocksList,
        error: null,
      };
    });
    builder.addCase(fetchSkadBlocksThunk.rejected, (state, action) => {
      const payload = action.payload as TSkadBlocksPayload | undefined;
      if (!payload) return;
      state[payload.bundleId] = {
        loadingBlocksState: LoadingState.failed,
        error: action.error?.message
          ? action.error
          : new Error("Unknown error"),
        blocksList: [],
      };
    });
  },
});

export default skadTemplatesSlice.reducer;

//selectors
export const getSkadBlocksByBundleId = (state, bundleId) =>
  state.skadBlocks[bundleId] ?? {};
