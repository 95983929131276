import { putAfter, removeAt } from "helpers/arrays";
import { addFloats, numberSort } from "helpers/primitive";
import { Integer } from "model/modelTypes";
import {
  IGroupBySplitData,
  IGroupedData,
  IGroupedRow,
  ILabeledData,
  ILabeledRow,
  IParsedRow,
  IRawTextData,
} from "model/report/reportData";
import {
  DimensionIds,
  Events,
  MetricDictionaryType,
  TCalculateMetric,
  fromCustomEventCPAName,
  fromCustomEventName,
} from "model/reporterDb/dbTableCampaign";
import { GRANULARITY_TYPES } from "model/reporterDb/granularity";
import * as R from "ramda";
import { DataDisplayMode } from "../dataDisplayMode";
import {
  identifyConvertData as defaultIdentifyConvertData,
  parseData as defaultParseData,
  foldAggrColumnsMulti,
} from "./helpers";

type IMeasure = { id: string; label?: string; columnType?: any };

export type IConstructorArgs = {
  measures: IMeasure[];
  granularity?: GRANULARITY_TYPES;
  groupBy: string[];
  rawTextData: IRawTextData;
  metricsDict?: MetricDictionaryType<any>;
  calculateMetricTotal?: TCalculateMetric;
  view?: DataDisplayMode;
  dateRange?: Date[] | string[];
  normalizeValuesFnDict?;
  normalizeGranularity?: (
    g?: GRANULARITY_TYPES,
    value?: string
  ) => string | undefined;
  columnConvertFns?;
  customHelperFns?: any;
};

type INormalize = () => IGroupedData | IGroupBySplitData;

type IGroupRowsRec = ({
  groupDepth,
  data,
}: {
  groupDepth: Integer;
  data: ILabeledData;
}) => { rows: IGroupedData; totals: ILabeledRow };

type ICalcTotals = (data: IGroupedData) => ILabeledRow;

const sortWithinGroup = (result: IGroupedData, groupId: string) => {
  if (
    //TODO this specific detail does not belong here
    groupId === DimensionIds.APP_BUNDLE_COLUMN_ID ||
    groupId === DimensionIds.CAMPAIGN_BUNDLE_COLUMN_ID ||
    groupId === DimensionIds.CITY_COLUMN_ID
  ) {
    return result.sort((a, b) =>
      numberSort(b[Events.impression], a[Events.impression])
    );
  }

  return result.sort((a, b) => a[groupId]?.localeCompare?.(b[groupId]));
};

export const reorderRowByGranularitySplit =
  (granularitySplitIndex: Integer) => (row: IParsedRow) => {
    const [granularity, ...restRow] = row;
    return putAfter({
      arr: restRow,
      index: granularitySplitIndex,
      value: granularity,
    });
  };
export class ReportNormalizer {
  aggregatableMeasures: string[]; // these measures totals = just adding values
  calculatedCPAMeasures: string[]; // CPA measures totals are calculated from custom events totals
  calculatedMeasures: string[]; // these measures totals are calculated from other measures totals
  granularity?: GRANULARITY_TYPES;
  groupBy: string[];
  labeledData: ILabeledData;
  calculateMetricTotal?: TCalculateMetric;
  view?: DataDisplayMode;
  dateRange?: Date[] | string[];
  normalizeFnDict?;
  normalizeGranularity?: (
    g?: GRANULARITY_TYPES,
    value?: string
  ) => string | undefined;
  columnIds: string[];

  helperFns: {
    parseData: (rawTextData: IRawTextData) => IParsedRow[];
    identifyConvertData: (
      parsedData: IParsedRow[],
      columnIds: string[],
      columnConvertFns: any
    ) => ILabeledData;
  };

  constructor({
    measures,
    granularity,
    groupBy,
    rawTextData,
    metricsDict,
    calculateMetricTotal,
    view,
    dateRange,
    normalizeValuesFnDict,
    normalizeGranularity,
    columnConvertFns,
    customHelperFns,
  }: IConstructorArgs) {
    this.view = view;
    this.dateRange = dateRange;
    this.aggregatableMeasures = [];
    this.calculatedCPAMeasures = [];
    this.calculatedMeasures = [];
    this.calculateMetricTotal = calculateMetricTotal;
    this.normalizeFnDict = normalizeValuesFnDict;
    this.normalizeGranularity = normalizeGranularity;
    this.helperFns = {
      parseData: customHelperFns?.parseData ?? defaultParseData,
      identifyConvertData:
        customHelperFns?.identifyConvertData ?? defaultIdentifyConvertData,
    };

    measures.forEach((m) => {
      if (metricsDict?.[m.id]) {
        this.calculatedMeasures.push(m.id);
      } else if (
        m.columnType === "AppMeasureCPA" ||
        m.columnType === "CohortedAppMeasureCPA"
      ) {
        this.calculatedCPAMeasures.push(m.id);
      } else if (m.columnType === "Dimension") {
        //do nothing
      } else {
        this.aggregatableMeasures.push(m.id);
      }
    });

    const indexOfGranularity = groupBy.indexOf("GRANULARITY");
    this.granularity = granularity;
    this.groupBy =
      granularity === "ALL" ? removeAt(groupBy, indexOfGranularity) : groupBy;
    this.columnIds = measures.map((m) => m.id);

    let parsedData = this.helperFns.parseData(rawTextData);

    if (granularity !== "ALL" && indexOfGranularity > -1) {
      //on server report is always grouped by granularity first, despite desired groupBy order
      parsedData = parsedData.map(
        reorderRowByGranularitySplit(indexOfGranularity)
      );
    }
    this.labeledData = this.helperFns.identifyConvertData(
      parsedData,
      this.columnIds,
      columnConvertFns
    );
  }

  normalize: INormalize = () => {
    if (!this.groupBy.length) {
      return this.normalizeValues(this.labeledData);
    }

    if (this.groupBy.length === 1) {
      return this.normalizeValues(
        sortWithinGroup(
          foldAggrColumnsMulti(
            this.labeledData,
            this.groupBy,
            this.columnIds,
            {
              default: R.sum,
            },
            false
          ),
          this.groupBy[0]
        )
      );
    }

    const { rows } = this.groupRows({ groupDepth: 0, data: this.labeledData });
    return rows;
  };

  normalizeValues = (data: any[] = []) => {
    return data?.map((currentRow) => {
      const normalized = { ...currentRow };
      Object.keys(currentRow).forEach((key) => {
        if (this.normalizeFnDict?.[key]) {
          // NOTE: preserve the original value as _key
          normalized[`_${key}`] = currentRow[key];
          normalized[key] = this.normalizeFnDict[key](
            currentRow[key],
            currentRow
          );
        }
        if (
          key === DimensionIds.GRANULARITY_COLUMN_ID &&
          this.normalizeGranularity
        ) {
          normalized[`_${key}`] = currentRow[key];
          normalized[key] = this.normalizeGranularity(
            this.granularity,
            currentRow[key]
          );
        }
      });
      return normalized;
    });
  };

  groupRows: IGroupRowsRec = ({ groupDepth, data }) => {
    const groupId = this.groupBy[groupDepth];

    if (groupDepth === this.groupBy.length - 1) {
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_topLevelSplit, ...restOfTheColumns] = this.columnIds;
      return {
        rows: this.normalizeValues(
          sortWithinGroup(
            foldAggrColumnsMulti(
              data,
              this.groupBy,
              restOfTheColumns,
              {
                default: R.sum,
              },
              false
            ),
            groupId
          )
        ),
        totals: this.calcTotals(data),
      };
    }

    let result: IGroupedData = [];

    const dataOrderedByGroup = data.sort((a, b) =>
      a[groupId]?.localeCompare?.(b[groupId])
    );
    let currentGroupValue;
    let startIndex = 0;
    let lastIndex = 0;

    while (startIndex < dataOrderedByGroup.length) {
      currentGroupValue = data[startIndex][groupId];

      while (
        data[lastIndex] &&
        data[lastIndex][groupId] === currentGroupValue
      ) {
        lastIndex++;
      }

      const { rows, totals } = this.groupRows({
        groupDepth: groupDepth + 1,
        data: data.slice(startIndex, lastIndex).map((row: IGroupedRow) => {
          //eslint-disabled-next-line @typescript-eslint/no-unused-vars
          const { [groupId]: _, ...restRow } = row;
          return restRow;
        }),
      });

      result.push({
        [groupId]: currentGroupValue,
        rows,
        ...totals,
      });

      startIndex = lastIndex;
    }

    result = this.normalizeValues(sortWithinGroup(result, groupId));

    return {
      rows: result,
      totals: this.calcTotals(result),
    };
  };

  calcTotals: ICalcTotals = (data) => {
    const totals = data.reduce((aggr, row) => {
      this.aggregatableMeasures.forEach((mId) => {
        aggr[mId] = addFloats([aggr[mId], row[mId]])?.toString();
      });
      return aggr;
    }, {});

    this.calculatedCPAMeasures.forEach((mId) => {
      const cpaEventName = fromCustomEventCPAName(mId);
      const hasCountKind = fromCustomEventName(cpaEventName)?.kind === "count";
      totals[mId] = hasCountKind //CPA is calculated based on count-kind events
        ? this.calculateMetricTotal
            ?.cpa({ ...totals, cpaEvent: totals[cpaEventName] })
            ?.toString?.()
        : "-";
    });

    this.calculatedMeasures.forEach((mId) => {
      totals[mId] = this.calculateMetricTotal?.[mId]?.(totals)?.toString?.();
    });
    return totals;
  };
}
