"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRAFFIC_GROUP_IDS_BACK_MAP = exports.TRAFFIC_FILTERS_DICTIONARY = exports.ETrafficFilterId = void 0;
const primitive_1 = require("../../helpers/primitive");
const query_1 = require("../../model/reporterDb/query");
const dbTableTraffic_1 = require("../reporterDb/dbTableTraffic");
const CREATIVE_SIZE_QUERY = (bannerFilterId, videoFilterId) => (value) => ({
    $or: [
        ...(0, query_1.STRICT_MULTI_LIKE_QUERY_CURRY)(bannerFilterId)(value.map((v) => v && v.replace(/_/g, ""))).$or,
        ...(0, query_1.STRICT_MULTI_LIKE_QUERY_CURRY)(videoFilterId)(value.map((v) => v && v.replace(/_/g, ""))).$or,
    ],
});
const NUMERIC_EXACT_QUERY_CURRY = (filterId) => (value) => (0, query_1.EXACT_QUERY_CURRY)(filterId)(value.map((v) => (0, primitive_1.toNumber)(v)));
exports.ETrafficFilterId = {
    APP_BUNDLE: dbTableTraffic_1.TrafficDimensionIds.APP_BUNDLE_COLUMN_ID,
    CONNECTION: dbTableTraffic_1.TrafficDimensionIds.CONNECTION,
    GEO_COUNTRY: dbTableTraffic_1.TrafficDimensionIds.GEO_COUNTRY,
    DEVICE_OS: dbTableTraffic_1.TrafficDimensionIds.DEVICE_OS,
    DEVICE_VERSION: dbTableTraffic_1.TrafficDimensionIds.DEVICE_VERSION,
    DEVICE_OS_VERSION: dbTableTraffic_1.TrafficDimensionIds.DEVICE_OS_VERSION,
    DEVICE_TYPE: dbTableTraffic_1.TrafficDimensionIds.DEVICE_TYPE,
    EXCHANGE: dbTableTraffic_1.TrafficDimensionIds.EXCHANGE,
    INVENTORY_CATEGORIES: dbTableTraffic_1.TrafficDimensionIds.INVENTORY_CATEGORIES,
    INVENTORY_CREATIVE_TYPE: dbTableTraffic_1.TrafficDimensionIds.INVENTORY_CREATIVE_TYPE,
    INVENTORY_TYPE: dbTableTraffic_1.TrafficDimensionIds.INVENTORY_TYPE,
    CREATIVE_FORMAT_SIZE: dbTableTraffic_1.TrafficDimensionIds.CREATIVE_FORMAT_SIZE,
    TRACKING_ALLOWED: dbTableTraffic_1.TrafficDimensionIds.TRACKING_ALLOWED,
    TAG_ID: dbTableTraffic_1.TrafficDimensionIds.TAG_ID,
};
// NOTE: Values for filters without dictionary are being queried from Reporter
exports.TRAFFIC_FILTERS_DICTIONARY = {
    [exports.ETrafficFilterId.APP_BUNDLE]: {
        id: exports.ETrafficFilterId.APP_BUNDLE,
        name: "App Bundle Id",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.APP_BUNDLE),
    },
    [exports.ETrafficFilterId.CONNECTION]: {
        id: exports.ETrafficFilterId.CONNECTION,
        dictionary: "device-connection-type",
        name: "Connection",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.CONNECTION),
    },
    [exports.ETrafficFilterId.GEO_COUNTRY]: {
        id: exports.ETrafficFilterId.GEO_COUNTRY,
        name: "Country",
        dictionary: "country",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.GEO_COUNTRY),
    },
    [exports.ETrafficFilterId.DEVICE_OS]: {
        id: exports.ETrafficFilterId.DEVICE_OS,
        name: "Device OS",
        dictionary: "device-os-type",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.DEVICE_OS),
    },
    [exports.ETrafficFilterId.DEVICE_VERSION]: {
        id: exports.ETrafficFilterId.DEVICE_VERSION,
        name: "Device OS Minor",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.DEVICE_VERSION),
    },
    [exports.ETrafficFilterId.DEVICE_OS_VERSION]: {
        id: exports.ETrafficFilterId.DEVICE_OS_VERSION,
        name: "Device OS Version",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.DEVICE_OS_VERSION),
    },
    [exports.ETrafficFilterId.DEVICE_TYPE]: {
        id: exports.ETrafficFilterId.DEVICE_TYPE,
        name: "Device Type",
        dictionary: "device-type",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.DEVICE_TYPE),
    },
    [exports.ETrafficFilterId.EXCHANGE]: {
        id: exports.ETrafficFilterId.EXCHANGE,
        name: "Exchange",
        dictionary: "exchange",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.EXCHANGE),
    },
    [exports.ETrafficFilterId.INVENTORY_CATEGORIES]: {
        id: exports.ETrafficFilterId.INVENTORY_CATEGORIES,
        name: "Inventory Categories",
        dictionary: "categories",
        query: (0, query_1.STRICT_MULTI_LIKE_QUERY_CURRY)(exports.ETrafficFilterId.INVENTORY_CATEGORIES),
    },
    [exports.ETrafficFilterId.INVENTORY_CREATIVE_TYPE]: {
        id: exports.ETrafficFilterId.INVENTORY_CREATIVE_TYPE,
        name: "Inventory Creative Type",
        dictionary: "inventory-creative-type",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.INVENTORY_CREATIVE_TYPE),
    },
    [exports.ETrafficFilterId.INVENTORY_TYPE]: {
        id: exports.ETrafficFilterId.INVENTORY_TYPE,
        name: "Inventory Type",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.INVENTORY_TYPE),
    },
    [exports.ETrafficFilterId.CREATIVE_FORMAT_SIZE]: {
        id: exports.ETrafficFilterId.CREATIVE_FORMAT_SIZE,
        name: "Creative Size",
        dictionary: "creative-sizes",
        combineDimensionIds: [
            dbTableTraffic_1.TrafficDimensionIds.BANNER_FORMAT_SIZE,
            dbTableTraffic_1.TrafficDimensionIds.VIDEO_PLAYER_ORIENTATION,
        ],
        type: "Dimension",
        isEmptyValue: (v) => !v || v === "0x0" || v === "[]",
        tooltip: {
            [dbTableTraffic_1.TrafficDimensionIds.BANNER_FORMAT_SIZE]: "Banner Format Size",
            [dbTableTraffic_1.TrafficDimensionIds.VIDEO_PLAYER_ORIENTATION]: "Video Player Size",
        },
        query: CREATIVE_SIZE_QUERY(dbTableTraffic_1.TrafficDimensionIds.BANNER_FORMAT_SIZE, dbTableTraffic_1.TrafficDimensionIds.VIDEO_PLAYER_ORIENTATION),
    },
    [exports.ETrafficFilterId.TRACKING_ALLOWED]: {
        id: exports.ETrafficFilterId.TRACKING_ALLOWED,
        name: "Has Device ID",
        staticDictionary: [
            { label: "Yes", value: "1" },
            { label: "No", value: "0" },
        ],
        query: NUMERIC_EXACT_QUERY_CURRY(exports.ETrafficFilterId.TRACKING_ALLOWED),
    },
    [exports.ETrafficFilterId.TAG_ID]: {
        id: exports.ETrafficFilterId.TAG_ID,
        name: "Tag ID",
        query: (0, query_1.EXACT_QUERY_CURRY)(exports.ETrafficFilterId.TAG_ID),
    },
};
//Map individual groupId back to combined groupId
//Example:
//{
// "BANNER_FORMAT_SIZE": "CREATIVE_FORMAT_SIZE",
// "VIDEO_PLAYER_ORIENTATION": "CREATIVE_FORMAT_SIZE"
//}
exports.TRAFFIC_GROUP_IDS_BACK_MAP = Object.entries(exports.TRAFFIC_FILTERS_DICTIONARY)
    .filter(([id, d]) => { var _a; return (_a = d.combineDimensionIds) === null || _a === void 0 ? void 0 : _a.length; })
    .reduce((acc, [id, d]) => {
    d.combineDimensionIds.forEach((combineId) => {
        acc[combineId] = id;
    });
    return acc;
}, {});
