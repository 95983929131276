import { ISOstring } from "@dataseat-dsp-client/lib-reporter";
import {
  differenceInDaysIso,
  endOfIsoDay,
  endOfISOWeekIso,
  endOfMonthIso,
  getIsoDateNow,
  startOfIsoDay,
  startOfISOWeekIso,
  startOfMonthIso,
  subDaysIso,
  subMonthsIso,
  subWeeksIso,
} from "helpers";
import { TimeRange, TimeRangeLabelsDict } from "model/report/dateRange";

export interface IQuickLink {
  id: TimeRange;
  value: [ISOstring, ISOstring] | [null, null];
  compareTo: [ISOstring, ISOstring] | [null, null];
  label: string;
}

export const today = () => getIsoDateNow();
export const yesterday = () => subDaysIso(today(), 1);
const dayBeforeYesterday = () => subDaysIso(today(), 2);
export const day7BeforeToday = () => subDaysIso(today(), 7);
const day8BeforeToday = () => subDaysIso(today(), 8);
export const day14BeforeToday = () => subDaysIso(today(), 14);
const day15BeforeToday = () => subDaysIso(today(), 15);
export const day30BeforeToday = () => subDaysIso(today(), 30);
const day28BeforeToday = () => subDaysIso(today(), 28);
const day31BeforeToday = () => subDaysIso(today(), 31);
const day60BeforeToday = () => subDaysIso(today(), 60);
const startOfThisWeek = () => startOfISOWeekIso(today());
const startOfLastWeek = () => subWeeksIso(startOfThisWeek(), 1);
const startOfWeekBeforeLast = () => subWeeksIso(startOfThisWeek(), 2);
const startOfThisMonth = () => startOfMonthIso(today());
const thisMonthLength = () => differenceInDaysIso(today(), startOfThisMonth());
const startOfLastMonth = () => subMonthsIso(startOfThisMonth(), 1);
const lastMonthLength = differenceInDaysIso(
  endOfMonthIso(startOfLastMonth()),
  startOfLastMonth()
);
const startOfMonthIsoBeforeLast = subMonthsIso(startOfThisMonth(), 2);

export function getQuickLinks(): { [key in TimeRange]?: IQuickLink } {
  return {
    [TimeRange.allTime]: {
      id: TimeRange.allTime,
      value: [null, null],
      compareTo: [null, null],
      label: TimeRangeLabelsDict.allTime,
    },
    [TimeRange.today]: {
      id: TimeRange.today,
      value: [startOfIsoDay(today()), endOfIsoDay(today())],
      compareTo: [startOfIsoDay(yesterday()), subDaysIso(today(), 1)],
      label: TimeRangeLabelsDict.today,
    },
    [TimeRange.yesterday]: {
      id: TimeRange.yesterday,
      value: [startOfIsoDay(yesterday()), endOfIsoDay(yesterday())],
      compareTo: [
        startOfIsoDay(dayBeforeYesterday()),
        endOfIsoDay(dayBeforeYesterday()),
      ],
      label: TimeRangeLabelsDict.yesterday,
    },
    [TimeRange.last7Days]: {
      id: TimeRange.last7Days,
      label: TimeRangeLabelsDict.last7Days,
      value: [startOfIsoDay(day7BeforeToday()), endOfIsoDay(yesterday())],
      compareTo: [
        startOfIsoDay(day14BeforeToday()),
        endOfIsoDay(day8BeforeToday()),
      ],
    },
    [TimeRange.last14Days]: {
      id: TimeRange.last14Days,
      label: TimeRangeLabelsDict.last14Days,
      value: [startOfIsoDay(day14BeforeToday()), endOfIsoDay(yesterday())],
      compareTo: [
        startOfIsoDay(day28BeforeToday()),
        endOfIsoDay(day15BeforeToday()),
      ],
    },
    [TimeRange.lastWeek]: {
      id: TimeRange.lastWeek,
      label: TimeRangeLabelsDict.lastWeek,
      value: [startOfLastWeek(), endOfISOWeekIso(startOfLastWeek())],
      compareTo: [
        startOfWeekBeforeLast(),
        endOfISOWeekIso(startOfWeekBeforeLast()),
      ],
    },
    [TimeRange.last30Days]: {
      id: TimeRange.last30Days,
      label: TimeRangeLabelsDict.last30Days,
      value: [startOfIsoDay(day30BeforeToday()), endOfIsoDay(yesterday())],
      compareTo: [
        startOfIsoDay(day60BeforeToday()),
        endOfIsoDay(day31BeforeToday()),
      ],
    },
    [TimeRange.mtd]: {
      id: TimeRange.mtd,
      label: TimeRangeLabelsDict.mtd,
      value: [startOfThisMonth(), endOfIsoDay(yesterday())],
      compareTo: [
        startOfIsoDay(
          subDaysIso(endOfMonthIso(startOfLastMonth()), thisMonthLength() - 1)
        ),
        endOfMonthIso(startOfLastMonth()),
      ],
    },
    [TimeRange.lastMonth]: {
      id: TimeRange.lastMonth,
      label: TimeRangeLabelsDict.lastMonth,
      value: [startOfLastMonth(), endOfMonthIso(startOfLastMonth())],
      compareTo: [
        startOfIsoDay(
          subDaysIso(endOfMonthIso(startOfMonthIsoBeforeLast), lastMonthLength)
        ),
        endOfMonthIso(startOfMonthIsoBeforeLast),
      ],
    },
  };
}
