"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADDITIVE_METRICS = exports.calculateMetricUnsafe = exports.calculateMetric = exports.bidFloor = exports.SKANIR = exports.SKANCPI = exports.averageRPI = exports.SKANCTI = exports.CTI = exports.CVR = exports.ecpiUnsafe = exports.eCPI = exports.eCPC = exports.CPR = exports.CPI = exports.IR = exports.CPC = exports.ROAS = exports.SKANIPM = exports.IPM = exports.winrate = exports.winrateRaw = exports.eCPMfromRawSpend = exports.eCPM = exports.CTR = exports.CTRRaw = void 0;
const metrics_1 = require("../../../helpers/metrics");
const primitive_1 = require("../../../helpers/primitive");
const numericMeasures_1 = require("./numericMeasures");
const CTRRaw = (impressions, clicks, round = 3) => {
    if (impressions === undefined || clicks === undefined || impressions === 0) {
        return undefined;
    }
    const ctrFloat = clicks / impressions;
    const mult = Math.pow(10, round);
    return Math.round(mult * ctrFloat) / mult;
};
exports.CTRRaw = CTRRaw;
const CTR = (impressions, clicks) => impressions > 0 ? Math.round((1000 * clicks) / impressions) / 10 : 0;
exports.CTR = CTR;
// Ecpm = 1000 * ( totalSpent / totalImpressions )
const eCPM = (spend, impressions) => impressions === 0 ? 0 : Math.round((100000 * spend) / impressions) / 100;
exports.eCPM = eCPM;
// `spend` in this case should be raw and not normalized, i.e.: 66500 instead of 0.07
const eCPMfromRawSpend = ({ spend, impressions = 0 }) => {
    if (impressions === 0)
        return 0;
    return Math.round((spend / 100 / impressions) * 10) / 100 || 0;
};
exports.eCPMfromRawSpend = eCPMfromRawSpend;
const winrateRaw = (bids, impressions, round = 3) => {
    if (bids === undefined || impressions === undefined || bids === 0) {
        return undefined;
    }
    const winrateFloat = impressions / bids;
    const mult = Math.pow(10, round);
    return Math.round(mult * winrateFloat) / mult;
};
exports.winrateRaw = winrateRaw;
const winrate = (bids, impressions) => bids === 0 ? 0 : Math.round((10000 * impressions) / bids) / 100;
exports.winrate = winrate;
/*
CPC = Spend / Clicks
IR = mmpInstalls / Clicks
CPI = Spend / mmpInstalls
CPR = Spend / Reattributions
eCPC = Spend / Clicks
eCPI (MMP CPI) = Spend / mmpInstalls
CVR = Installs / Clicks as %
SKAN CPI = Spend / skadInstalls
SKAN IR = Spend / skadInstalls
IPM = 1000 * mmpInstalls / Impressions
SKAN IPM = 1000 * skadInstalls / Impressions
ROAS = Revenue / Spend
*/
const IPM = ({ mmpInstall, impressions, }) => (impressions > 0 ? (0, primitive_1.roundUp)((1000 * mmpInstall) / impressions, 2) : 0);
exports.IPM = IPM;
const SKANIPM = ({ skadInstall, impressions, }) => (impressions > 0 ? (0, primitive_1.roundUp)((1000 * skadInstall) / impressions, 2) : 0);
exports.SKANIPM = SKANIPM;
const ROAS = ({ revenue, spend, }) => (0, primitive_1.percentage)({ part: (0, metrics_1.dollarsToMicro)(revenue), total: spend });
exports.ROAS = ROAS;
const CPC = ({ spend, click, }) => (0, primitive_1.safeDivision)(spend, click);
exports.CPC = CPC;
const IR = ({ mmpInstall, click }) => (0, primitive_1.safeDivision)(100 * mmpInstall, click);
exports.IR = IR;
const CPI = ({ spend, mmpInstall, }) => (0, primitive_1.safeDivision)(spend, mmpInstall);
exports.CPI = CPI;
const CPR = ({ spend, reattribution, }) => (0, primitive_1.safeDivision)(spend, reattribution);
exports.CPR = CPR;
const eCPC = ({ spend, click, }) => (0, primitive_1.safeDivision)(spend, click);
exports.eCPC = eCPC;
const eCPI = ({ spend, mmpInstall, }) => (0, primitive_1.safeDivision)(spend, mmpInstall);
exports.eCPI = eCPI;
const ecpiUnsafe = ({ spend, mmpInstall, }) => spend === 0 ? 0 : mmpInstall === 0 ? Infinity : (0, exports.eCPI)({ spend, mmpInstall });
exports.ecpiUnsafe = ecpiUnsafe;
const CVR = ({ install, click }) => (0, primitive_1.percentage)({ part: install, total: click });
exports.CVR = CVR;
const CTI = ({ install, click }) => (0, primitive_1.safeDivision)(install, click);
exports.CTI = CTI;
const SKANCTI = ({ skadInstall, click }) => (0, primitive_1.safeDivision)(skadInstall, click);
exports.SKANCTI = SKANCTI;
const averageRPI = ({ mmpInstall }) => (0, primitive_1.safeDivision)(1, mmpInstall);
exports.averageRPI = averageRPI;
const SKANCPI = ({ spend, skadInstall, }) => (0, primitive_1.safeDivision)(spend, skadInstall);
exports.SKANCPI = SKANCPI;
const SKANIR = ({ skadInstall, click }) => (0, primitive_1.safeDivision)(100 * skadInstall, click);
exports.SKANIR = SKANIR;
const bidFloor = ({ bidfloorSumDividend, impression } //for calulating bidFloor total in reports
) => +((0, primitive_1.safeDivision)(bidfloorSumDividend, impression) / 1000000).toFixed(2);
exports.bidFloor = bidFloor;
//safe calcs: Infinity is treated as 0
exports.calculateMetric = {
    [numericMeasures_1.Metrics.winrate]: ({ bid = 0, impression = 0 }) => (0, exports.winrate)(bid, impression) / 100,
    [numericMeasures_1.Metrics.ecpm]: ({ spend = 0, impression = 0 }) => (0, exports.eCPM)(spend, impression),
    [numericMeasures_1.Metrics.ctr]: ({ impression = 0, click = 0 }) => (0, exports.CTR)(impression, click),
    cpc: (data) => (0, exports.CPC)(data),
    [numericMeasures_1.Metrics.ir]: (data) => (0, exports.IR)(data),
    cpi: (data) => (0, exports.CPI)(data),
    [numericMeasures_1.Metrics.cpr]: (data) => (0, exports.CPR)(data),
    [numericMeasures_1.Metrics.ecpc]: (data) => (0, exports.eCPC)(data),
    [numericMeasures_1.Metrics.ecpi]: (data) => (0, exports.eCPI)(data),
    [numericMeasures_1.Metrics.cti]: (data) => (0, exports.CTI)(data),
    [numericMeasures_1.Metrics.skanCPI]: (data) => (0, exports.SKANCPI)(data),
    [numericMeasures_1.Metrics.skanIR]: (data) => (0, exports.SKANIR)(data),
    [numericMeasures_1.Metrics.ipm]: (data) => (0, exports.IPM)(data),
    [numericMeasures_1.Metrics.skanIPM]: (data) => (0, exports.SKANIPM)(data),
    [numericMeasures_1.Metrics.roas]: (data) => (0, exports.ROAS)(data),
    [numericMeasures_1.Metrics.bidFloor]: (data) => (0, exports.bidFloor)(data),
    [numericMeasures_1.Metrics.cpa]: ({ spend = 0, cpaEvent = 0 }) => (0, primitive_1.safeDivision)(spend, cpaEvent),
    [numericMeasures_1.Metrics.skanCpa]: ({ spend = 0, skanCpaEvent = 0 }) => (0, primitive_1.safeDivision)(spend, skanCpaEvent),
    [numericMeasures_1.Metrics.skanFineRatio]: ({ skanFineRatioDividend = 0, skanFineRatioDivisor = 0, }) => (0, primitive_1.safeDivision)(skanFineRatioDividend, skanFineRatioDivisor),
    [numericMeasures_1.Metrics.skanCoarseRatio]: ({ skanCoarseRatioDividend = 0, skanCoarseRatioDivisor = 0, }) => (0, primitive_1.safeDivision)(skanCoarseRatioDividend, skanCoarseRatioDivisor),
    [numericMeasures_1.Metrics.skanDigit1Ratio]: ({ skanDigit1RatioDividend = 0, skanDigit1RatioDivisor = 0, }) => (0, primitive_1.safeDivision)(skanDigit1RatioDividend, skanDigit1RatioDivisor),
    [numericMeasures_1.Metrics.skanDigit2Ratio]: ({ skanDigit2RatioDividend = 0, skanDigit2RatioDivisor = 0, }) => (0, primitive_1.safeDivision)(skanDigit2RatioDividend, skanDigit2RatioDivisor),
    [numericMeasures_1.Metrics.skanSourceAppIdRatio]: ({ skanSourceAppIdRatioDividend = 0, skanSourceAppIdRatioDivisor = 0, }) => (0, primitive_1.safeDivision)(skanSourceAppIdRatioDividend, skanSourceAppIdRatioDivisor),
};
//allows Infinity
exports.calculateMetricUnsafe = Object.assign(Object.assign({}, exports.calculateMetric), { [numericMeasures_1.Metrics.ecpi]: (data) => (0, exports.ecpiUnsafe)(data) });
exports.ADDITIVE_METRICS = {};
